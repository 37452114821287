@charset "UTF-8";
@import "../common/_settings/_setting";

/**
 * index
 */
body[id="notfound"] {
  .section {
    &__notfound {
      &.-con01 {
        @include mq-pc {
          margin-top: 8rem;
        }
        @include mq-sp {
          margin-top: (160 / 375 * 100) + vw;
        }
      } //con01
      .__titleLevel02 {
        @include mq-pc {
          margin-bottom: 4rem;
        }
        @include mq-sp {
          margin-top: (19 / 375 * 100) + vw;
        }
      } //__titleLevel02
      .readMore {
        @include mq-pc {
          margin-top: 6.5rem;
        }
        @include mq-sp {
          margin-top: (33 / 375 * 100) + vw;
        }
      } //readMore
    } //__notfound
  } //section
} //notfound
